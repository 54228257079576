import React, { useEffect } from "react";
import Header from '../components/header'
import { graphql } from "gatsby"
import * as styles from './courses.module.scss'

const Course = ({ data }) => {
    useEffect(() => {
        //check for local
        let local = JSON.parse(localStorage.getItem('recentlyViewed'))

        if (local) {
            //check if already exists
            let find = local.findIndex(item => item.name === data.courses.name)

            //check position in array - if already first do nothing
            if (find > 0) {
                local.splice(find)
                local.unshift(data.courses)
            } else if (find < 0) {
                local.unshift(data.courses)
            }
        } else {
            local = []
            //start the storage
            local.unshift(data.courses)
        }

        //trim if longer than 5
        if (local.length > 4) {
            local.splice(-(local.length - 4))
        }

        localStorage.setItem('recentlyViewed', JSON.stringify(local))

    }, [])

    return (
        <div>
            <Header />
            <div className={styles.courses}>
                
                <div className={styles.courses__info}>
                    <h1 dangerouslySetInnerHTML={{ __html: data.courses.name }} />    
                    <p>{data.courses.address}</p>
                </div>

                {data.courses.cloudflare && <img className={styles.courses__image} src={`https://data.golfip.com/cdn-cgi/imagedelivery/PYt1FRAR9QKRNk22qcI1qw/${data.courses.cloudflare}/public`} />}
            </div>
        </div>
    )
}

export const query = graphql`
    query($name: String) {
        courses(name: { eq: $name }) {
            name
            slug
            cloudflare
            address
        }
    }
`;

export default Course;