// extracted by mini-css-extract-plugin
export var courses = "courses-module--courses--DjHnk";
export var courses__image = "courses-module--courses__image--P3LYP";
export var courses__info = "courses-module--courses__info--3D3tF";
export var fadeheight = "courses-module--fadeheight--XHQE1";
export var fadein = "courses-module--fadein--Ry-QP";
export var hintro = "courses-module--hintro--SYHr0";
export var moveup = "courses-module--moveup--QL2qp";
export var pulse = "courses-module--pulse--irELu";
export var rotate = "courses-module--rotate--Eros1";
export var rotatealt = "courses-module--rotatealt--vDx6c";
export var rotatealtrev = "courses-module--rotatealtrev--0qlmd";
export var rotaterev = "courses-module--rotaterev--9y-Le";
export var speed = "courses-module--speed--hmlzA";
export var squeeze = "courses-module--squeeze--l+EkP";